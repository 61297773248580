require('@rails/ujs').start()

// Wir haben hier doppelte Redirects weil sich 
// Turbolinks auch in der legacy_application.js
// befindet:
require('turbolinks').start()
require('jquery')
//require('@rails/activestorage').start()
require('chart.js')
require('chartkick')
require('channels')

import 'cocoon';

// jQuery Dependencies
import $ from 'jquery';
global.$ = $
global.jQuery = $
import '../src/select2.custom';
import '../src/jquery-ui';
import '../src/sortable.custom';
import '../src/foundation-datepicker.js';
import '../src/foundation-datepicker.custom.js';
import '../src/confetti.browser.min.js';
import '../src/custom.ui.js';
