// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function() {

  function sortableList(list_selector, list_item_class, direction){
    $(list_selector).sortable({
      axis: direction,
      dropOnEmpty: false,
      items: list_item_class,
      opacity: 0.4,
      scroll: true,
      update: function(){
        $.ajax({
          type: 'patch',
          data: $(this).sortable('serialize'),
          dataType: 'script',
          complete: function(request){
            $(list_selector).effect( "highlight", {color:"#E1F3E1"}, 1500 );
          },
          url: $(this).attr('data-sort-path'),
          error:function (xhr, msg, error) {
            alert(msg + ": " + error);
          }
        })
      }
    });
  }

  sortableList('ul.sortable-todo_items', 'li', 'y');
  sortableList('ul.sortable-todo_item_templates', 'li', 'y');
  sortableList('ul.sortable-eventtypes', 'li', 'y');
  sortableList('ul.sortable-feedback_questions_participants', 'li', 'y');
  sortableList('ul.sortable-feedback_questions_trainers', 'li', 'y');
  sortableList('ul.sortable-feedback_questions_mc_options', 'li', 'y');
  sortableList('ul.sortable-cloth_sizes', 'li', 'y');
  sortableList('ul.sortable-birthday_options', 'li', 'y');
  sortableList('ul.sortable-birthday_categories', 'li', 'y');
  sortableList('ul.sortable-trainer_questions', 'li', 'y');
  sortableList('ul.sortable-licences', 'li', 'y');
  sortableList('ul.sortable-planning_entry_templates', 'li', 'y');
  sortableList('ul.sortable-planning_entries', 'li', 'y');
  sortableList('ul.sortable-product_groups', 'li.product-group', 'y');
  sortableList('ul.sortable-products', 'li.product', 'y');
  sortableList('ul.sortable-product_variants', 'li.product_variant', 'y');
  sortableList('ul.sortable-topbar_items', 'li', 'y');
  sortableList('ul.sortable-upload_containers', 'li', 'y');
  sortableList('ul.sortable-host_deals', 'li', 'y');
  sortableList('ul.sortable-timeslots', 'li', 'y');

  $('ul.sortable-photos').sortable({
    dropOnEmpty: false,
    items: 'li.photo',
    opacity: 0.4,
    scroll: true,
    update: function(){
      $.ajax({
        type: 'patch',
        data: $(this).sortable('serialize'),
        dataType: 'script',
        complete: function(request){
          $('ul.sortable-photos').effect( "highlight", {color:"#E1F3E1"}, 1500 );
        },
        url: $(this).attr('data-sort-path'),
        error:function (xhr, msg, error) {
          alert(msg + ": " + error);
        }
      })
    }
  });

});
