//$(document).on('turbolinks:load', function() {
$(document).on('turbolinks:load', function() {

  $('.datepicker').fdatepicker();

	$.fn.fdatepicker.dates['de'] = {
		days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
		daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam", "Son"],
		daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
		months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
		monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
		today: "Heute"
	};

	$.fn.fdatepicker.dates['en'] = {
		days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
		daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
		months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		today: "Today",
		clear: "Clear",
		weekStart: 1,
		format: "dd/mm/yyyy"
	};

  var now = new Date(); 

  $('.fdatepicker').fdatepicker({
    weekStart: 1,
    format: 'dd.mm.yyyy hh:ii',
    disableDblClickSelection: true,
    pickTime: true,
    leftArrow:'<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
    rightArrow:'<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>',
    language: "de",
    closeButton: false
  });

  $('.fdatepicker-date-only').fdatepicker({
    weekStart: 1,
    format: 'dd.mm.yyyy',
    disableDblClickSelection: true,
    pickTime: false,
    leftArrow:'<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
    rightArrow:'<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>',
    language: "de",
    closeButton: false
  });

  $.fn.fdatepicker.defaults.language = 'de';

});
