import 'select2';

$(document).on('turbolinks:load', function() {
  $(".select2").select2();

  $('#employment-form').on('cocoon:after-insert', function() {
    $(".select2").select2();
  })

  $('.select2-cocoon').on('cocoon:after-insert', function() {
    $(".select2").select2();
  })

  function init_tag_list(id) {
    var tags_for_selection_str = $(id).attr("data-tags");
    if(tags_for_selection_str) {
      var tags_for_selection_ary = tags_for_selection_str.split(",");
      $(id).select2({
        tags:tags_for_selection_ary,
        tokenSeparators: [",", " "]
      });
    }
  }
});

$(document).on('nested:fieldAdded', function(event){
  var field = event.field; 
  var select2_field = field.find('.select2');
  select2_field.select2();
})
